html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "nunito-sans";
}

.pointer {
  cursor: pointer;
}

.ivy-mode {
  font-family: "ivymode";
}

#logo {
  position: absolute;
  top: 1rem;
  bottom: auto;
  left: 1rem;
  right: auto;
  z-index: 3;
  height: 30px;
  width: 100px;
  background-size: contain;
}

#panorama {
  width: 100%;
  height: 100%;
}

.pnlm-container {
  background: #222;
  font-family: "nunito-sans";
}

.pnlm-controls-container,
.pnlm-about-msg {
  display: none !important;
}

.pnlm-controls {
  background-color: rgba(255, 255, 255, 0.4);
  border: none;
}

.pnlm-panorama-info {
  bottom: 0.8rem;
}

.pnlm-title-box {
  font-size: 0.9rem;
  padding: 0.4rem 0.4rem 0.2rem 1rem;
}

.pnlm-hotspot-base:not(.tooltip-open):hover {
  box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.8);
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 50px;
}

.pnlm-hotspot-base {
  transition: box-shadow 0.4s ease-in-out;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.8);
  border-radius: 10rem;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.pnlm-hotspot-base span {
  width: auto !important;
  margin-left: -1px !important;
  white-space: nowrap;
}

.pnlm-hotspot-base.tooltip-open {
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
  color: #000;
}

.pnlm-tooltip.tooltip-open span {
  visibility: visible !important;
  background-color: rgba(255, 255, 255, 0.7);
  color: #000;
}

.pnlm-tooltip.tooltip-open span::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.7) transparent transparent transparent;
  bottom: -20px;
  left: -10px;
  margin: 0 50%;
}

.pnlm-hotspot-base.tooltip-open:hover span::after {
  border-color: rgba(255, 255, 255, 0.7) transparent transparent transparent;
}

.pnlm-hotspot-base.tooltip-open {
  cursor: auto;
}

.icon {
  background-size: cover;
  background-position: center !important;
  width: 40px;
  height: 40px;
}

.icon > .pnlm-pointer {
  margin-left: 0px !important;
}

.icon-walking-left {
  background-image: url("../../image/ico-walk-left.png");
}

.icon-walking-right {
  background-image: url("../../image/ico-walk-right.png");
}

.icon-walking-around {
  background-image: url("../../image/ico-walk-around.png");
}

.icon-arrow-down {
  background-image: url("../../image/ico-arrow-down.png");
}

.icon-arrow-up {
  background-image: url("../../image/ico-arrow-up.png");
}

.icon-pin-poi {
  background-image: url("../../image/ico-pin-poi.png");
}

.pnlm-compass {
  bottom: 0.6rem;
  right: 0.6rem;
  background-size: 40px;
  width: 40px;
  height: 40px;
}

.pnlm-load-box {
  border-radius: 16px;
  top: auto;
  left: calc(50% - 175px);
  height: 30px;
  width: 350px;
  padding: 15px 0;
  margin: 0;
  bottom: 30px;
}

.pnlm-load-box p:first-child {
  font-size: 0;
  margin: 0;
}

.pnlm-lbar {
  width: 320px;
  border-radius: 16px;
  height: 3px;
}

p.pnlm-lmsg {
  margin: 0;
  margin-top: 0.8rem;
  color: #aaaaaa;
  font-weight: 700;
}

.pnlm-lmsg::before {
  content: "Loading... ";
}

.controls {
  bottom: 9px;
}

@media (max-width: 768px) {
  .pnlm-compass,
  .controls {
    bottom: 72px;
  }

  .one-scene {
    bottom: 20px;
  }
}
